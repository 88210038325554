.button {
  display: block;
  border: none;
  border-radius: 5px;
  min-width: 38px;
  width: fit-content;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #000000;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 39px;

  &:hover {
    filter: brightness(120%);
  }
}

.transparent {
  background-color: transparent;
}

.success {
  background-color: #0b8128;
  color: #ffffff;
}

.error {
  background-color: #ff0f0f;
  color: #ffffff;
}

.add {
  width: 60px;
}

.amber {
  background-color: #d07d01;
  color: #ffffff;
}

.blue {
  color: #4aadd4;
}

.green {
  color: #0b8128;
}

.disabled {
  background-color: #a1a0a0 !important;
  color: #ffffff;

  &:hover {
    filter: none;
    cursor: default;
  }
}

.slim {
  padding: 4px 8px;
  width: 38px;
}

.wide {
  width: 164px;
}
