.skill-definition-table {
  @media screen and (max-width: 1200px) {
    max-width: 70%;
  }

  td {
    line-height: 1.5;
    font-size: 15px;
  }
}
