form {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  .css-2b097c-container {
    width: 280px;
  }
  .skill-level {
    width: 150px;
  }
}

.employee-info-form {
  display: flex;
  align-items: center;

  & .form-group:first-child {
    margin-right: 30px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .button {
    margin-right: 20px;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
}
