.user-table-container {
  height: 440px;
  overflow-y: auto;
  margin-bottom: 20px;
  overflow-x: hidden;
}

.users-table {
  margin-bottom: 20px;

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  tr:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal-button {
    width: 150px;
    background-color: #eeeeee;
    padding: 10px 20px;
    border-radius: 5px;
  }
}

.table-titles {
  color: black;
  font-weight: 500;

  &-basic {
    font-weight: 500;
    cursor: auto;
  }

  &:hover{
    color: mediumblue;
  }

  &.active{
    font-weight: bolder;
    color: mediumblue;
  }

  &:active{
    font-weight: bolder;
    color: mediumblue;
  }
}

.desc > .fa-chevron-down {
    transform: rotate(180deg);
}