$duration: 0.5s;
$navUnderlineColour: #15abc5;

.nav-item {
  line-height: 1.7;
  
  @media screen and (min-width: 441px) {
    font-size: 20px;
  }

  @media screen and (max-width: 440px) {
    font-size: 16px;
  }

  a {
    &.active:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: $navUnderlineColour;
      width: 100%;
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: $navUnderlineColour;
      transition: width $duration;
    }
    &:hover::after {
      width: 100%;
    }
  }
}
