@import './global-styles';

body,
table,
div {
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
    border: 3px solid #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.page-heading {
  margin-bottom: 10px;
  text-align: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
  margin-right: 20px;

  input {
    height: 37px;
    width: 230px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    outline: 0 !important;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    text-overflow: ellipsis;
    padding: 2px 8px;
    color: hsl(0, 0%, 20%);
    margin: 0 2px;
    font-size: 16px;
  }
}

.form-group {
  .basic-single {
    width: 257px;
  }
}

table {
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  font-size: 16px;

  th {
    background-color: #eeeeee;
  }

  td,
  th {
    @media screen and (min-width: $mediaMaxWidth) {
      padding: 5px 34px;
      font-size: $desktop-font-size;
    }

    @media screen and (min-width: $mediaTabletWidth) and (max-width: $mediaMaxWidth) {
      padding: 5px 12px;
      font-size: $desktop-font-size;
    }

    @media screen and (max-width: $mediaTabletWidth) {
      padding: 5px 30px;
      font-size: $desktop-font-size;
    }

    @media screen and (max-width: $mediaMinWidth) {
      padding: 5px 0;
      font-size: $mobile-font-size;
    }

    text-align: start;
  }
}

.warning {
  color: red;
}

.row {
  display: flex;
}

.full-width {
  width: 100%;
}

.spacing {
  padding: 10px;

  &-top {
    padding-top: 10px;
  }

  &-bottom {
    padding-bottom: 10px;
  }

  &-left {
    padding-left: 10px;
  }

  &-right {
    padding-right: 10px;
  }
}

.no-spacing {
  padding: 0;
}

.hide {
  display: none;
}

.text-xsmall {
  font-size: x-small;
}

.text-xxsmall {
  font-size: xx-small;
}
