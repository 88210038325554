@import '../../sass/base';

header {
  background-color: #223344;
  color: #fff;
  z-index: 8;
  box-shadow: (0 3px 6px -6px #222);

  @media screen and (min-width: 963px) {
    min-height: 112px;
  }

  @media screen and (max-width: 962px) {
    min-height: 80px;
  }
}