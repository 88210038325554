.admin-users-table-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.admin-users-table {
  max-height: 650px;
  width: 100%;
  overflow-y: auto;
  display: table;

  th {
    padding: 5px 18px;
    text-align: left;
  }

  td {
    text-align: left;
    padding: 5px 18px;
  }
}

.add-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
}

.edit-delete {
  display: flex;
}

.user-skills {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
