.submit-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.reference-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  table {
    max-height: 400px;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;


    .labelValue-name {
      min-width: 220px;
    }

    .skill-for-group-name {
      min-width: 250px;
    }

  }

  .new-form {
    margin: 12px 0;

    .button {
      margin-top: 23px;
      margin-left: 6px;
    }

    .input-group { 
      width: 85%;

      input {
        width: 100%;
      }
    }
  }
}

.react-tabs {
  width: 100%;

  .react-tabs__tab-panel {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 50px 0 50px;
  }
}

.label-name {
  width: 90%;
}

.fa-trash-alt {
  color: rgb(172, 31, 31);

  &:hover {
    color: rgb(224, 18, 18);
    cursor: pointer;
  }
}
