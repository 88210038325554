.modal-table-container {
  max-height: 600px;
  overflow-y: auto;
  width: 100%;

  .modal-table {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.react-tabs__tab-panel--selected {
  overflow-x: hidden;
}

.bar-chart {
  width: 100%;
  background-color: #eeeeee;
  padding: 20px;

  .bar-row-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    position: relative;

    .bar {
      padding: 5px 3px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 12px;
      color: #ffffff;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.tooltip:hover {
  cursor: pointer;
}

.tooltip:hover span {
  opacity: 1;
  filter: alpha(opacity=100);
  top: -54px;
  z-index: 99;
}

.tooltip span {
  z-index: 0;
  background: none repeat scroll 0 0 #222;
  color: #f0b015;
  font-family: 'Helvetica';
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5em;
  padding: 10px;
  width: auto;
  top: -100%;
  margin-left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  text-transform: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-in-out;
}

.tooltip span:after {
  border-color: #222 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -5px;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  width: 0;
}