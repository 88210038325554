@tailwind base;
@tailwind components;
@tailwind utilities;

@import './sass/base';
@import './sass/helpers';

@layer base {
  @font-face {
    font-family: 'Montserrat-Black';
    src: local('Montserrat-Black'), url('./assets/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 200;
  }

  @font-face {
    font-family: 'Montserrat-Regular';
    src: local('Montserrat-Regular'), url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat-Medium';
    src: local('Montserrat-Medium'), url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  }

  h1 {
    font-size: 39.06px;
    font-weight: 600;
    font-family: 'Montserrat-Medium', sans-serif;
  }
  h2 {
    font-size: 31.25px;
    font-family: 'Montserrat-Medium', sans-serif;
  }
  h3 {
    font-size: 25px;
    font-family: 'Montserrat-Medium', sans-serif;
  }
  h4 {
    font-size: 20px;
    font-family: 'Montserrat-Medium', sans-serif;
  }
  @screen lg {
    h1 {
      font-size: 48.83px;
      font-weight: 600;
      font-family: 'Montserrat-Medium', sans-serif;
    }
    h2 {
      font-size: 39.06px;
      font-family: 'Montserrat-Medium', sans-serif;
    }
    h3 {
      font-size: 31.25px;
      font-family: 'Montserrat-Medium', sans-serif;
    }
    h4 {
      font-size: 20px;
      font-family: 'Montserrat-Medium', sans-serif;
    }
  }
}

@layer components {
  .wrapper {
    max-width: 1280px;
    margin: 0 auto;

    @media screen and (min-width: 963px) {
      margin-top: 60px;
    }

    @media screen and (max-width: 962px) {
      margin-top: 50px;
    }
  }
}

body {
  margin: 0;
  font-family: 'Montserrat-Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto; /* fallback */
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
