.admin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    font-size: 20px;
  }
  
  .filter-inputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .filter-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    background-color: #eeeeee;
    padding: 20px 60px;

    .filter-dropdown {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;
      margin-right: 20px;

      .select__control{
        min-width: 230px;
      }
    }
  }

  .data-container {
    display: flex; 
    justify-content: space-between;
  }

  .pie-container {
    width: 500px;
    background-color: #eeeeee;
    padding: 20px;
  }

  .user-list-tab {
    width: 100%;
    display: flex;
    justify-content: center;

    .user-list-container{ 
      display: flex;
      flex-direction: column;
      align-items: flex-end;
  
      span {
        display: inline-block;
        width: 164px;
      }
    }
  }
}

#skills {
  min-width: 230px;
}

.button-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 15px;
}
