@import '../../sass/base';

.skills-page-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  align-items: center;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.skills-description-table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;

  @media screen and (max-width: 1200px) {
    margin-left: 0;
    margin-top: 50px;
  }
}
