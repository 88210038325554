// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaTabletWidth: 960px;
$mediaMinWidth: 480px;

//Colours
$color-primary: #c69963;
$color-primary-dark: #b28451;

$color-secondary: #101d2c;

$color-grey-light-1: #f9f7f6;
$color-grey-light-2: #aaa;

$color-grey-dark-1: #54483a;
$color-grey-dark-2: #6d5d4b;

//Font Sizes
$desktop-font-size: 1rem;
$mobile-font-size: 0.5rem;