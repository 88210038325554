.skills-wrapper {
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
  display: flex;
  justify-content: center;

  .skills {
    width: 100%;
    .skills-table {
      width: 100%;

      .skills-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      td {
        height: 50px;
        padding: 5px 16px;
      }

      th {
        padding: 5px 16px;
      }
    }
  }
}

.skills-form {
  .button {
    margin-top: 23px;
  }
}


