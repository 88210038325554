.ReactModal__Overlay {
  z-index: 10;
}

.user-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;

  h2 {
    padding-top: 2px;
  }

  form {
    margin-top: 0;
  }

  .user-form {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    width: 550px;
    margin-bottom: 20px;

    input {
      width: 253px !important;
    }

    h4 {
      margin: 20px 0;
    }

    .skills-form {
      margin-top: 12px;
    }

    .permissions {
      display: flex;
      justify-content: space-between;
    }

    .no-skills-text {
      margin-top: 19px;
    }
  }

  .buttons-container {
    width: 450px;
    display: flex;
    justify-content: space-between;
  }
}
